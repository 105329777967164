import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 350px;
  width: 500px;
  border: 1px solid lightgray;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 8px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 75%;
  background: ${(props) => props.backgroundImage || 'lightblue'};
  padding: 8px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 25%;
  padding: 12px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-self: flex-start;
  background: ${(props) => props.logo || 'none'};
  width: 30px;
  height: 30px;
`;

const Header = styled.h1`
  display: flex;
  align-self: center;
  margin-bottom: 0;
  max-width: 410px;
`;

const SubHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Body = styled.div`
  font-size: 15px;
`;

const Link = styled.a`
  font-size: 14px;
  color: #9c9292;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

const Profile = styled.div`
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  padding: 4px 0;
`;

const ProfileName = styled.div`
  color: lightblue;
  font-size: 20px;
  font-weight: bold;
  margin: 0 4px 0 8px;
  align-self: center;
`;

const ProfileImage = styled.div`
  background-color: gray;
  min-width: 30px;
  min-height: 30px;
  margin: 0 4px;
`;

const SocialCard = (props) => {
  const {
    backgroundImage,
    headerText,
    subHeaderText,
    bodyText,
    link,
    user,
    logo,
  } = props;

  return (
    <Card>
      <HeaderContainer backgroundImage={backgroundImage}>
        <LogoContainer logo={logo} />
        <Header>{headerText}</Header>
        <Profile>
          <ProfileName>{user.name}</ProfileName>
          <ProfileImage />
        </Profile>
      </HeaderContainer>
      <BodyContainer>
        <SubHeader>{subHeaderText}</SubHeader>
        <Body>{bodyText}</Body>
        {link ? (
          <Link href={link.href} target="_blank">
            {link.display || link.href}
          </Link>
        ) : null}
      </BodyContainer>
    </Card>
  );
};

SocialCard.propTypes = {
  backgroundImage: PropTypes.string,
  headerText: PropTypes.string,
  subHeaderText: PropTypes.string,
  bodyText: PropTypes.string,
  link: PropTypes.shape({
    href: PropTypes.string,
    display: PropTypes.string,
  }),
  logo: PropTypes.string,
  user: PropTypes.shape({
    name: PropTypes.string,
    ProfileImage: PropTypes.string,
    profileUrl: PropTypes.string,
  }),
};

SocialCard.defaultProps = {
  headerText: 'Learning React? Start Small.',
  subHeaderText: 'Learning React? Start Small.',
  bodyText:
    "Can't pry yourself away from tutorials? The cure is to make tiny little experiment apps.",
  logo: '',
  user: {},
};

const SocialCardProject = ({ data }) => {
  const socialCards = [
    {
      user: {
        name: 'Michael',
        profileUrl: 'http://www.michaelchamoures.com',
      },
      link: {
        href: 'https://espn.com',
      },
      logo: 'black',
    },
    {
      headerText: 'This is a React practice app',
      subHeaderText: "It's all about expaning your knowledge of React",
      bodyText: 'If you want to learn more, click the link below',
      logo: 'black',
      user: {
        name: 'James',
        profileUrl: 'http://www.michaelchamoures.com',
      },
      link: {
        href: 'https://espn.com',
      },
    },
  ];

  return (
    <PageContainer>
      {socialCards.map((socialCard) => (
        <SocialCard {...socialCard} />
      ))}
    </PageContainer>
  );
};

export default SocialCardProject;
